import FqApi from '@/services/fq-api'
import ProtoBufApi from '@/services/protobuf-api'
import ProtoBuf from 'protobufjs'

export default {
  async getEntitySummaries(filter) {
    const serialised = this.serialiseSalesAnalysisFilter(filter)
    const url = `salesanalysis/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getClientDropdownProto(filter) {
    const serialised = this.serialiseQuickInvoiceAssetDropdownFilter(filter)
    const result = await ProtoBufApi(`/quickinvoices/protobuf/assets/listcombo/${serialised}`)
    const proto = require('../../assets/proto/quickinvoice/QuickInvoiceAssetDropdown.proto')
    const message = proto.QuickInvoiceAssetDropdown.QuickInvoiceAssetDropdownModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.assets
  },
  serialiseSalesAnalysisFilter(filter) {
    const entityProto = require('../../assets/proto/salesanalysis/SalesAnalysisFilter.proto')
    const filterMessage = entityProto.SalesAnalysisFilter.SalesAnalysisFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  serialiseQuickInvoiceAssetDropdownFilter(filter) {
    const entityProto = require('../../assets/proto/quickinvoice/QuickInvoiceAssetDropdownFilter.proto')
    const filterMessage = entityProto.QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  }
}
