<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <sales-analysis-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column is-wrapped">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entitySummaries"
                :key="entity.assetId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>{{ $filters.formatDateTimezone(entity.invoiceDate, $userInfo.locale) }}</td>
                <td>{{ entity.debtorName }}</td>
                <td v-if="$company.isCategoryEnabled(itemCategoryTypes.RR)"
                  class="has-text-right">{{ $filters.formatNumber(entity.rrTotal, $userInfo.locale) }}</td>
                <td v-if="$company.isCategoryEnabled(itemCategoryTypes.RWA)"
                  class="has-text-right">{{ $filters.formatNumber(entity.rwaTotal, $userInfo.locale) }}</td>
                <td v-if="$company.isCategoryEnabled(itemCategoryTypes.REP)"
                  class="has-text-right">{{ $filters.formatNumber(entity.repairTotal, $userInfo.locale) }}</td>
                <td v-if="$company.isCategoryEnabled(itemCategoryTypes.PAINT)"
                  class="has-text-right">{{ $filters.formatNumber(entity.paintTotal + entity.opgTotal, $userInfo.locale) }}</td>
                <td v-if="$company.isCategoryEnabled(itemCategoryTypes.FIBER)"
                  class="has-text-right">{{ $filters.formatNumber(entity.fgTotal, $userInfo.locale) }}</td>
                <td v-if="$company.isCategoryEnabled(itemCategoryTypes.CRUSH)"
                  class="has-text-right">{{ $filters.formatNumber(entity.crushTotal, $userInfo.locale) }}</td>
                <td v-if="$company.isCategoryEnabled(itemCategoryTypes.CD)"
                  class="has-text-right">{{ $filters.formatNumber(entity.cdTotal, $userInfo.locale) }}</td>
                <td v-if="$company.isCategoryEnabled(itemCategoryTypes.MECH)"
                  class="has-text-right">{{ $filters.formatNumber(entity.mechTotal, $userInfo.locale) }}</td>
                <td v-if="$company.isCategoryEnabled(itemCategoryTypes.PART)"
                  class="has-text-right">{{ $filters.formatNumber(entity.partTotal, $userInfo.locale) }}</td>
                <td v-if="$company.isCategoryEnabled(itemCategoryTypes.MISC)"
                  class="has-text-right">{{ $filters.formatNumber(entity.miscTotal, $userInfo.locale) }}</td>
                <td v-if="$company.isCategoryEnabled(itemCategoryTypes.SUBL)"
                  class="has-text-right">{{ $filters.formatNumber(entity.subletTotal, $userInfo.locale) }}</td>
                <td v-if="$company.isCategoryEnabled(itemCategoryTypes.PDRRR)"
                  class="has-text-right">{{ $filters.formatNumber(entity.pdrrrTotal, $userInfo.locale) }}</td>
                <td v-if="$company.isCategoryEnabled(itemCategoryTypes.PDRRE)"
                  class="has-text-right">{{ $filters.formatNumber(entity.pdrreTotal, $userInfo.locale) }}</td>
                <td class="has-text-right">{{ $filters.formatNumber(entity.painM, $userInfo.locale) }}</td>
                <td class="has-text-right">{{ $filters.formatNumber(entity.orTotals, $userInfo.locale) }}</td>
                <td class="has-text-right">{{ $filters.formatNumber(entity.excessAmount, $userInfo.locale) }}</td>
                <td class="has-text-right">{{ $filters.formatNumber(entity.totalExGST, $userInfo.locale) }}</td>
                <td class="has-text-right">{{ $filters.formatNumber(entity.gst, $userInfo.locale) }}</td>
                <td class="has-text-right">{{ $filters.formatNumber(entity.totalIncGst, $userInfo.locale) }}</td>
                <td>{{ entity.invoiceNo }}</td>
                <td>{{ entity.quoteNo }}</td>
                <td>{{ entity.claimNo }}</td>
                <td>{{ entity.rego }}</td>
                <td>{{ entity.ownerName }}</td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
    <sales-analysis-print-modal v-if="isPrintSalesAnalysisModalActive"
      :active.sync="isPrintSalesAnalysisModalActive"
      @print="printSalesAnalysis"
      @export="exportToExcel"
      @close="closePrint" />
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import { AppHeaderButtonTypes } from '@/enums'
import SalesAnalysisListSideMenu from './SalesAnalysisListSideMenu'
import SalesAnalysisService from './SalesAnalysisService'
import SalesAnalysisValidation from './SalesAnalysisValidation'
import SalesAnalysisPrintModal from './SalesAnalysisPrintModal'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import BulmaTable from '@/components/BulmaTable'
import { Columns } from './columns'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import PrintPreviewService from '@/components/printpreview/PrintPreviewService'
import { KeyValuePairModel } from '@/classes/viewmodels'
import { Emailer } from '@/classes'
import StoreMixin from './storeMixin'
import { DateTime } from 'luxon'
import { ItemCategoryTypes } from '@/enums'

export default {
  name: 'SalesAnalysisListView',
  components: {
    AppDetailHeader,
    [SalesAnalysisListSideMenu.name]: SalesAnalysisListSideMenu,
    SalesAnalysisPrintModal,
    BulmaTable
  },
  filters: {},
  mixins: [DateTimeFiltersMixin, SalesAnalysisValidation, NumberFiltersMixin, StoreMixin],
  props: {},
  data() {
    return {
      entitySummaries: [],
      totalRows: 0,
      filter: {
        // dateFrom: new Date(new Date().setMonth(new Date().getMonth() - 1)),
        // dateTo: new Date(),
        dateFrom: '',
        dateTo: '',
        selectDateType: 'selectDates',
        clientName: '',
        assetId: '',
        pageSize: 15,
        pageIndex: 1,
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder
      },
      toggle: true,
      selectedRow: null,
      isTableLoading: false,
      isPrintSalesAnalysisModalActive: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    },
    itemCategoryTypes() {
      return ItemCategoryTypes
    }
  },
  watch: {},
  created() {
    let d = new Date(new Date().setMonth(new Date().getMonth() - 1))
    this.filter.dateFrom = d.toUTCString()
    this.filter.dateTo = new Date().toUTCString()
    this.getEntitySummaries()
  },
  mounted() {
    this.$v.filter.$touch()
  },
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    filterList() {
      if (this.$v.filter.$error) {
        this.$notification.openNotificationWithType('danger', 'Sales Analysis', 'Filter validation errors.')
        return false
      }
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getEntitySummaries()
    },
    sort(name, order) {
      //this.filter.sortBy = name
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getEntitySummaries()
    },
    resetList(selectedFromDate, selectedToDate) {
      this.filter.dateFrom = selectedFromDate
      this.filter.dateTo = selectedToDate
      this.filter.selectDateType = 'selectDates'
      this.filter.assetId = ''
      this.filter.clientName = ''
      this.filter.sortColumn = Columns[0].name
      this.filter.sortOrder = Columns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 15
      this.persistFilter()
      this.getEntitySummaries()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getEntitySummaries()
    },
    async getEntitySummaries() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await SalesAnalysisService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    async print() {
      this.isPrintSalesAnalysisModalActive = true
    },
    closePrint() {
      this.isPrintSalesAnalysisModalActive = false
    },
    generateReportParams: (function (report) {
      const sortBy = this.filter.sortColumn && this.filter.sortOrder ? `${this.filter.sortColumn} ${this.filter.sortOrder}` : ''
      let params = Object.assign({
        CompanyID: this.$userInfo.companyId,
        FromSalesDate: this.filter.dateFrom === '' ? '1754-12-31' : this.filter.dateFrom,
        EndSaleDate: this.filter.dateTo === '' ? '9999-12-31' : this.filter.dateTo,
        Client: this.filter.assetId,
        AllDates: this.filter.dateFrom === '' && this.filter.dateTo === '' ? true : false,
        ClientStringName: this.filter.clientName,
        SortBy: sortBy,
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      })
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      return keyValuePairs
    }),
    async exportToExcel(format) {
      const keyValuePairs = this.generateReportParams('SalesAnalysisExcel')
      await PrintPreviewService.exportReportEx('rptSalesAnalysisExcel', format, keyValuePairs)
      this.isPrintSalesAnalysisModalActive = false
    },
    async printSalesAnalysis(report) {
      const keyValuePairs = this.generateReportParams(report)
      this.addReportParameters(keyValuePairs)

      var reportName = ''
      var emailSubject = ''
      if (report == 'SalesAnalysis') {
        reportName = 'rptSalesList'
        emailSubject = 'Sales Analysis'
      } else if (report == 'SalesAnalysisExcel') {
        reportName = 'rptSalesAnalysisExcel'
        emailSubject = 'Sales Analysis Export to Excel'
      }

      const emailer = new Emailer()
      emailer.subject = emailSubject
      emailer.reportName = reportName
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: reportName },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })

      this.isPrintSalesAnalysisModalActive = false
    },
    print1() {
      const sortBy = this.filter.sortColumn && this.filter.sortOrder ? `${this.filter.sortColumn} ${this.filter.sortOrder}` : ''
      let params = Object.assign({
        CompanyID: this.$userInfo.companyId,
        FromSalesDate: this.filter.dateFrom === '' ? '1754-12-31' : DateTime.fromISO(this.filter.dateFrom).toISODate(),
        EndSaleDate: this.filter.dateTo === '' ? '9999-12-31' : DateTime.fromISO(this.filter.dateTo).toISODate(),
        Client: this.filter.assetId,
        AllDates: this.filter.dateFrom === '' && this.filter.dateTo === '' ? true : false,
        ClientStringName: this.filter.clientName,
        SortBy: sortBy,
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      })

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.subject = 'Sales Analysis Report'
      emailer.reportName = 'rptSalesList'
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.is-wrapped {
  overflow-x: auto;
  width: 100%;
}
.table {
  font-size: 0.75rem;
}
</style>
