import { mapGetters, mapActions } from 'vuex'

const moduleName = 'salesanalysis'

export default {
  computed: {
    ...mapGetters(moduleName, {
      returnRoute: 'returnRoute',
      snapshots: 'snapshots',
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      snapshotDiff: 'snapshotDiff'
    })
  },
  methods: {
    ...mapActions('printpreview', { setPrintPreviewReturnRoute: 'setReturnRoute', addEmailer: 'addEmailer', removeEmailer: 'removeEmailer' }),
    ...mapActions('printpreview', { addReportParameters: 'addParameters', removeReportParameters: 'removeParameters' })
  }
}
