import { TableColumn } from '@/classes'
import store from '@/store'
import { ItemCategoryTypes } from '@/enums'

const company = store.getters['company/company']

const startColumns = [
  new TableColumn('Inv Date', 'invoiceDate', true, 'desc', 'left'),
  new TableColumn('Debtor Name', 'debtorName', true, 'desc', 'left')
]

const endColumns = [
  new TableColumn('PAINM', 'painM', true, 'asc', 'right'),
  new TableColumn('Other Work', 'orTotals', true, 'asc', 'right'),
  new TableColumn('Excess', 'excessAmount', true, 'asc', 'right'),
  new TableColumn('Total Excl GST', 'totalExGst', true, 'asc', 'right'),
  new TableColumn('GST', 'gst', true, 'asc', 'right'),
  new TableColumn('Total Incl GST', 'totalIncGst', true, 'asc', 'right'),
  new TableColumn('Inv No', 'invoiceNoFromSp', true, 'desc', 'left'),
  new TableColumn('Quote No', 'quoteNo', true, 'desc', 'left'),
  new TableColumn('Claim No', 'claimNoFromSp', true, 'desc', 'left'),
  new TableColumn('Rego No', 'regoNoFromSp', true, 'desc', 'left'),
  new TableColumn('Owner Name', 'ownerName', true, 'desc', 'left')
]

const categoryTotalMapping = [
  { category: ItemCategoryTypes.RR, total: 'rrTotal' },
  { category: ItemCategoryTypes.RWA, total: 'rwaTotal' },
  { category: ItemCategoryTypes.REP, total: 'repairTotal' },
  { category: ItemCategoryTypes.PAINT, total: 'paintTotal' },
  { category: ItemCategoryTypes.FIBER, total: 'fgTotal' },
  { category: ItemCategoryTypes.CRUSH, total: 'crushTotal' },
  { category: ItemCategoryTypes.CD, total: 'subletTotal' },
  { category: ItemCategoryTypes.MECH, total: 'cdTotal' },
  { category: ItemCategoryTypes.PART, total: 'partTotal' },
  { category: ItemCategoryTypes.MISC, total: 'miscTotal' },
  { category: ItemCategoryTypes.SUBL, total: 'subletTotal' },
  { category: ItemCategoryTypes.PDRRR, total: 'pdrrrTotal' },
  { category: ItemCategoryTypes.PDRRE, total: 'pdrreTotal' }
]

let generatedColumns = []
const excludedCategories = ['QUICK', ItemCategoryTypes.OPG]

if (company.itemCategories != null)
{
  company.itemCategories.forEach((category) => {
    if (category.enabled && !excludedCategories.includes(category.itemCategoryType)) {
      const totalColumn = categoryTotalMapping.find((m) => m.category === category.itemCategoryType)
      generatedColumns.splice(
        generatedColumns.length,
        1,
        new TableColumn(category.displayName, totalColumn.total, true, 'desc', 'right')
      )
    }
  })
}

let Columns = [...startColumns, ...generatedColumns, ...endColumns]

// let Columns = [
//   new TableColumn('Inv Date', 'invoiceDate', true, 'desc', 'left'),
//   new TableColumn('Debtor Name', 'debtorName', true, 'desc', 'left'),
//   new TableColumn('R&R', 'rrTotal', true, 'asc', 'left'),
//   new TableColumn('RWA', 'rwaTotal', true, 'desc', 'left'),
//   new TableColumn('Repair', 'repairTotal', true, 'asc', 'left'),
//   new TableColumn('Paint', 'paintTotal', true, 'asc', 'centered'),
//   new TableColumn('Parts', 'partTotal', true, 'desc', 'left'),
//   new TableColumn('Misc', 'miscTotal', true, 'desc', 'left'),
//   new TableColumn('Sublet', 'subletTotal', true, 'desc', 'left'),
//   new TableColumn('FG', 'fgTotal', true, 'desc', 'left'),
//   new TableColumn('MECH', 'mechTotal', true, 'desc', 'left'),
//   new TableColumn('CRUSH', 'crushTotal', true, 'asc', 'left'),
//   new TableColumn('CD', 'cdTotal', true, 'desc', 'left'),
//   new TableColumn('Other Work', 'orTotals', true, 'asc', 'left'),
//   new TableColumn('Total', 'totalExGst', true, 'asc', 'centered'),
//   new TableColumn('Inv No', 'invoiceNoFromSp', true, 'desc', 'left'),
//   new TableColumn('Quote No', 'quoteNo', true, 'desc', 'left'),
//   new TableColumn('Claim No', 'claimNoFromSp', true, 'desc', 'left'),
//   new TableColumn('Rego No', 'regoNoFromSp', true, 'desc', 'left'),
//   new TableColumn('Owner Name', 'ownerName', true, 'desc', 'left')
// ]

export { Columns }
