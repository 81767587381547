//import { required } from 'vuelidate/lib/validators'
//import { guidValidator } from '@/components/validators'

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    filter: {
      dateFrom: {
        validDate: true // aussieDateValidator
      },
      dateTo: {
        validDate: true // aussieDateValidator
      }
    }
  }
}
